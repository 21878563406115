import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { HOMEPAGE_PATH } from '../routeConstants'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

export default () => {
  useEffect(() => {
    navigate(`${HOMEPAGE_PATH}?utm_source=from_portfolio_owner`)
  }, [])
  return null
}
